import React from 'react';

const Footer = () => {
  return (
    // Footer.js
    <footer className="bg-gray-900 text-white py-6 mt-auto">
      <div className="container mx-auto text-center">
        <p className="text-lg font-semibold">&copy; 2024 BINO. All rights reserved.</p>
        <p className="mt-2">
          Δημιουργήθηκε από: Δημήτριος Ντάλντος 
        </p>
        <p className="mt-2">
        Για προτάσεις ή προβλήματα, επικοινωνήστε μαζί μου μέσω της "Επικοινωνία" κουμπί στην αρχή.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
