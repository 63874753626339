import React, { useState } from 'react';
import { loginUser } from '../services/api';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const response = await loginUser({ email, password });
      console.log('Login successful:', response);
  
      // Check for required fields in the response
      if (response?.user_id) {
        localStorage.setItem('user_id', response.user_id);
  
        if (response?.email) {
          localStorage.setItem('user_email', response.email); // Save email to localStorage
  
          // Check if the user is an admin
          const adminEmail = process.env.REACT_APP_ADMIN_EMAIL || 'dimitrios.n007@gmail.com';
          if (response.email.toLowerCase() === adminEmail.toLowerCase()) {
            localStorage.setItem('is_admin', 'true');
          } else {
            localStorage.setItem('is_admin', 'false');
          }
        } else {
          console.warn('No email found in response. Admin features will not work.');
          localStorage.removeItem('user_email');
          localStorage.setItem('is_admin', 'false');
        }
  
        setIsLoggedIn(true);
        console.log('User logged in successfully, navigating to Home');
        setTimeout(() => navigate('/'), 0);
      } else {
        console.error('Invalid login response:', response);
        setError(response?.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };
  
    

  const handleCreateAccount = () => {
    navigate('/register');  
  };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
      <img 
        src="/logo.png" 
        alt="Logo" 
        className="mx-auto mb-4 w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
      />
      <h2 className="text-3xl font-bold text-center text-blue-600">Είσοδος</h2>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full ${loading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'} text-white py-2 rounded-lg`}
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <div className="text-center mt-4">
        <span className="text-sm text-gray-600">Δεν έχετε λογαριασμό?</span>
        <button
          type="button"
          onClick={handleCreateAccount}
          className="ml-2 text-sm text-blue-600 hover:text-blue-800"
        >
          Δημιουργήστε ένα
        </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
