import React, { useCallback, useEffect, useState, useRef } from 'react';
import PredictionResults from '../components/PredictionResults';
import WinningNumbers from '../components/WinningNumbers';
import Loader from '../Loader';
import ComparisonOverview from '../components/ComparisonOverview';
import DonationModal from '../components/DonationModal';
import {
  fetchLast20WinningNumbers,
  fetchPenultimateWinningNumbers,
  fetchPredictions,
  fetchComparisonResult,
  getUserId,
  saveComparisonResult as apiSaveComparisonResult,
  submitContactMessage,
  submitReview
} from '../services/api';

const Home = () => {
  const [last20Numbers, setLast20Numbers] = useState([]);
  const [penultimateNumbers, setPenultimateNumbers] = useState([]);
  const [predictions, setPredictions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0); // Countdown timer

  const [manualRefresh, setManualRefresh] = useState(false);

  // Modal states
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactMessage, setContactMessage] = useState('');
  const [contactStatus, setContactStatus] = useState(null);

  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState('');
  const [reviewStatus, setReviewStatus] = useState(null);

  const userId = getUserId();
  console.log('User ID:', userId);

  const isFetchingRef = useRef(false);

  // Function to calculate the number of successful predictions
  const calculateSuccessCount = (predictions, winningNumbers) => {
    let successCount = 0;
    if (Array.isArray(predictions)) {
      predictions.forEach((prediction) => {
        prediction.forEach((num) => {
          if (winningNumbers.includes(num)) {
            successCount++;
          }
        });
      });
    } else {
      console.error('Predictions is not an array:', predictions);
    }
    return successCount;
  };

  // Function to calculate the time remaining until the next refresh
  const calculateNextRefresh = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    // Removed 'seconds' as it's not used

    // Define your refresh intervals in minutes
    const intervals = [1, 6, 11, 16, 21, 26, 31, 36, 41, 46, 51, 56];
    const nextInterval = intervals.find((min) => min > minutes % 60) || intervals[0];
    const nextRefreshTime = new Date();

    if (nextInterval <= minutes % 60) {
      // Move to the next hour if the interval has already passed in the current hour
      nextRefreshTime.setHours(now.getHours() + 1);
    }
    nextRefreshTime.setMinutes(nextInterval);
    nextRefreshTime.setSeconds(30);
    nextRefreshTime.setMilliseconds(0);

    return Math.floor((nextRefreshTime - now) / 1000); // Time in seconds
  };

  // Function to fetch comparison data
  const fetchComparisonData = useCallback(async () => {
    try {
      const comparison = await fetchComparisonResult();
      if (comparison?.success) {
        setComparisonResult(comparison.result);
      } else {
        throw new Error('Failed to fetch comparison results.');
      }
    } catch (err) {
      setError(`Error fetching comparison results: ${err.message}`);
    }
  }, []);

  // Function to save comparison results
  const saveComparisonResult = useCallback(
    (predictions, winningNumbers) => {
      const predictionsArray = Object.values(predictions).map((prediction, index) => ({
        prediction_id: index + 1,
        predicted_numbers: prediction,
        drawn_numbers: winningNumbers,
        user_id: userId,
        success_count: calculateSuccessCount([prediction], winningNumbers),
      }));
  
      console.log('Sending comparison result:', predictionsArray);
  
      return apiSaveComparisonResult(predictionsArray, winningNumbers)
        .then((response) => {
          console.log('Comparison result saved:', response);
        })
        .catch((error) => {
          console.error('Error saving comparison result:', error.response?.data || error.message);
        });
    },
    [userId]
  );

  // Function to fetch prediction data
  const fetchPredictionData = useCallback(async () => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;

    try {
      setLoading(true);
      const userId = getUserId();
      if (!userId) throw new Error('User ID is missing. Please log in.');

      const predictionResult = await fetchPredictions(userId);
      if (predictionResult?.success) {
        const predictionsArray = Object.values(predictionResult.predictions);
        setPredictions(predictionsArray);

        console.log('Fetched Predictions:', predictionsArray);

        await fetchComparisonData();

        const successCount = calculateSuccessCount(predictionsArray, last20Numbers);
        const predictedNumbersCount = predictionsArray.reduce((count, prediction) => count + prediction.length, 0);

        console.log('Saving success counts with data:', {
          user_id: userId,
          predicted_numbers_count: predictedNumbersCount,
          success_count: successCount,
        });

        await saveComparisonResult(predictionsArray, last20Numbers);
      } else {
        throw new Error('Failed to fetch predictions.');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
      isFetchingRef.current = false;
      setManualRefresh(false);
    }
  }, [last20Numbers, saveComparisonResult, fetchComparisonData]);

  // Function to fetch initial data (winning numbers)
  const fetchInitialData = useCallback(async () => {
    try {
      setLoading(true);
      console.log("Fetching last 20 winning numbers...");
      const last20 = await fetchLast20WinningNumbers();
      if (last20?.success) {
        setLast20Numbers(last20.data);
        console.log("Last 20 winning numbers:", last20.data);
      } else {
        console.error("Failed to fetch last 20 winning numbers.");
      }
  
      console.log("Fetching penultimate winning numbers...");
      const penultimate = await fetchPenultimateWinningNumbers();
      if (penultimate?.success) {
        setPenultimateNumbers(penultimate.penultimate_20_winning_numbers);
        console.log("Penultimate winning numbers:", penultimate.penultimate_20_winning_numbers);
      } else {
        console.error("Failed to fetch penultimate winning numbers.");
      }
    } catch (err) {
      setError(`Error fetching initial data: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);
  

  // Initial data fetch on component mount
  useEffect(() => {
    fetchInitialData().then(() => {
      // After initial data fetch, run predictions once
      if (last20Numbers.length > 0) {
        fetchPredictionData();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Countdown and Refresh based on specified times
  useEffect(() => {
    const initializeCountdown = () => {
      const secondsUntilNextRefresh = calculateNextRefresh();
      setTimeRemaining(secondsUntilNextRefresh);
    };
  
    initializeCountdown();
  
    const countdownInterval = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          console.log("Refreshing data...");
          fetchInitialData();
          fetchPredictionData();
          return calculateNextRefresh();
        }
        return prev - 1;
      });
    }, 1000); // Decrement every second
  
    return () => clearInterval(countdownInterval);
  }, [fetchInitialData, fetchPredictionData]);
  

  // Handle manual refresh
  const handleManualRefresh = async () => {
    if (!manualRefresh) {
      setManualRefresh(true);
      await fetchInitialData();
      await fetchPredictionData();
      setManualRefresh(false);
    }
  };

  // Handle contact form submission
  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setContactStatus('Αποστολή σε εξέλιξη...');
    try {
      const data = await submitContactMessage(userId, contactMessage);
      if (data.success) {
        setContactStatus('Το μήνυμά σας στάλθηκε με επιτυχία!');
      } else {
        setContactStatus(data.error || 'Αποτυχία αποστολής μηνύματος.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setContactStatus('Παρουσιάστηκε σφάλμα. Προσπαθήστε ξανά αργότερα.');
    }
  };

  // Handle reviews form submission
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setReviewStatus('Υποβολή σε εξέλιξη...');
    try {
      const data = await submitReview(userId, parseInt(rating, 10), comment);
      if (data.success) {
        setReviewStatus('Η αξιολόγησή σας καταχωρήθηκε επιτυχώς!');
      } else {
        setReviewStatus(data.error || 'Αποτυχία υποβολής αξιολόγησης.');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      setReviewStatus('Παρουσιάστηκε σφάλμα. Προσπαθήστε ξανά αργότερα.');
    }
  };

  const stars = [1, 2, 3, 4, 5];
  const handleStarClick = (starValue) => {
    setRating(starValue);
  };

  return (
    <div className="home-page p-4 relative min-h-screen bg-gradient-to-br from-blue-50 to-blue-100">
      <h1 className="text-center text-4xl font-bold text-blue-800 mt-6">Προβλέψεις Λοταρίας</h1>
      {loading && <Loader />}
      {error && <p className="error-message text-red-600">{error}</p>}

      {/* Countdown Timer */}
      <div className="text-center my-4">
        <div className="inline-flex items-center justify-center bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white text-2xl font-bold py-2 px-6 rounded-lg shadow-lg">
          <svg
            className="w-6 h-6 mr-2 animate-spin text-yellow-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
          </svg>
          Επόμενη ενημέρωση σε:
          <span className="ml-2 text-yellow-300">
            {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')}
          </span>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 space-y-4 md:space-y-0 mt-6">
        <button
          onClick={handleManualRefresh}
          className={`bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg shadow-md ${
            manualRefresh ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={manualRefresh}
        >
          Ανανέωση Προβλέψεων
        </button>
        <button
          onClick={() => setShowContactModal(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md"
        >
          Επικοινωνία
        </button>
        <button
          onClick={() => setShowReviewsModal(true)}
          className="bg-purple-500 hover:bg-purple-600 text-white px-6 py-2 rounded-lg shadow-md"
        >
          Αξιολογήσεις
        </button>
      </div>

      {/* Predictions Section */}
      <PredictionResults predictions={predictions} />

      {/* Winning Numbers Section */}
      <div className="winning-section mt-10">
        <WinningNumbers last20Numbers={last20Numbers} penultimateNumbers={penultimateNumbers} />
      </div>

      {/* Comparison Overview */}
      {comparisonResult && <ComparisonOverview comparisonResult={comparisonResult} />}

      {/* Donation Section */}
      <div className="donation-section mt-10 text-center">
        <h2 className="text-2xl font-bold text-blue-800 mb-4">Ευχαριστούμε για τη Στήριξή σας!</h2>
        <p className="text-lg text-gray-700 mb-4">
          Εάν απολαμβάνετε τις προβλέψεις μας και θέλετε να υποστηρίξετε το έργο μας, μπορείτε να κάνετε μια δωρεά!
        </p>
        <div className="flex justify-center space-x-4 mt-4">
          <a
            href="https://www.paypal.com/donate/?hosted_button_id=JJD29TK9MLS5W"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-yellow-500 hover:bg-yellow-600 text-white px-6 py-2 rounded-lg shadow-md"
          >
            Κάντε Δωρεά
          </a>
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md"
          >
            Μάθετε Περισσότερα
          </button>
        </div>

        {showModal && <DonationModal onClose={() => setShowModal(false)} />}
      </div>

      {/* Contact Modal */}
      {showContactModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <button
              onClick={() => setShowContactModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-4 flex items-center justify-center">
              <span className="mr-2">✉️</span> Επικοινωνία
            </h2>
            {contactStatus && (
              <div className="text-center mb-4 text-gray-700 font-semibold">
                {contactStatus}
              </div>
            )}
            <form onSubmit={handleContactSubmit} className="space-y-4">
              <textarea
                rows="5"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Πληκτρολογήστε το μήνυμά σας..."
                value={contactMessage}
                onChange={(e) => setContactMessage(e.target.value)}
              />
              <button
                type="submit"
                className="w-full bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg font-semibold transition duration-300"
              >
                Αποστολή Μηνύματος
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Reviews Modal */}
      {showReviewsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <button
              onClick={() => setShowReviewsModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-4 flex items-center justify-center">
              <span className="mr-2">⭐</span> Αξιολογήσεις
            </h2>
            {reviewStatus && (
              <div className="text-center mb-4 text-gray-700 font-semibold">
                {reviewStatus}
              </div>
            )}
            <form onSubmit={handleReviewSubmit} className="space-y-6">
              <div>
                <label className="block text-lg font-medium mb-2">Βαθμολογία</label>
                <div className="flex items-center space-x-2">
                  {stars.map((star) => (
                    <span
                      key={star}
                      className={`cursor-pointer text-3xl ${
                        star <= rating ? 'text-yellow-400' : 'text-gray-300'
                      }`}
                      onClick={() => handleStarClick(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <p className="text-sm text-gray-500 mt-1">Επιλέξτε από 1 έως 5 αστέρια.</p>
              </div>
              <div>
                <label htmlFor="comment" className="block text-lg font-medium mb-2">Σχόλιο (προαιρετικό)</label>
                <textarea
                  id="comment"
                  rows="3"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                  placeholder="Προσθέστε κάποιο σχόλιο..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold transition duration-300"
              >
                Υποβολή Αξιολόγησης
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
