import React from 'react';

const About = () => {
  const strategies = [
    {
      title: "Split Bet",
      description:
        "Με αυτή τη στρατηγική, οι αριθμοί σας χωρίζονται σε δύο ή περισσότερες ομάδες. Για παράδειγμα, αν επιλέξετε 10 αριθμούς, μπορείτε να τους χωρίσετε σε δύο ομάδες των 5 αριθμών η καθεμία.",
    },
    {
      title: "Way Bet",
      description:
        "Αυτή η στρατηγική σας επιτρέπει να ορίσετε διαφορετικές ομάδες αριθμών για τα πονταρίσματά σας. Για παράδειγμα, μπορείτε να επιλέξετε μια ομάδα με 4 αριθμούς και μια άλλη με 6 αριθμούς, δημιουργώντας συνδυασμούς που αυξάνουν τις πιθανότητες επιτυχίας.",
    },
    {
      title: "Top/Bottom",
      description:
        "Σε αυτή τη στρατηγική, το δελτίο σας χωρίζεται σε δύο οριζόντια τμήματα: το πάνω (1-40) και το κάτω (41-80). Επιλέξτε αριθμούς από το ένα τμήμα για να μειώσετε την πολυπλοκότητα.",
    },
    {
      title: "Edge Bet",
      description:
        "Το Edge Bet επικεντρώνεται στους αριθμούς που βρίσκονται στις άκρες του δελτίου. Αυτοί οι αριθμοί μπορεί να περιλαμβάνουν τους αριθμούς 1-10, 71-80, και συγκεκριμένους αριθμούς όπως το 11 ή το 20.",
    },
    {
      title: "Left/Right",
      description:
        "Σε αυτή τη στρατηγική, το δελτίο σας χωρίζεται το δελτίο κάθετα σε δύο μέρη: αριστερό και δεξί. Επιλέξτε αριθμούς μόνο από μία πλευρά για μια πιο στοχευμένη στρατηγική.",
    },
  ];

  return (
    <div className="strategy-instructions p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-blue-800 text-center mb-6">
        Τεχνολογία & Προβλέψεις
      </h1>
      <p className="text-lg text-gray-700 mb-6">
        Η εφαρμογή μας χρησιμοποιεί τεχνολογία μηχανικής μάθησης για την παροχή
        αξιόπιστων προβλέψεων. Οι αριθμοί που προτείνουμε βασίζονται σε
        προηγμένες αναλύσεις δεδομένων, αυξάνοντας τις πιθανότητες επιτυχίας σας..
      </p>
      <h1 className="text-3xl font-bold text-blue-800 text-center mb-6">
        Στρατηγικές και Συμβουλές για το ΚΙΝΟ
      </h1>
      <p className="text-lg text-gray-700 bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg shadow-sm mb-6">
        Το ΚΙΝΟ είναι ένα συναρπαστικό παιχνίδι τύχης που συνδυάζει ενθουσιασμό
        και προσδοκίες. Παρόλο που η τύχη παίζει τεράστιο ρόλο, μπορείτε να
        αυξήσετε τις πιθανότητές σας χρησιμοποιώντας στρατηγικές και συστήματα που παρέχονται από το μοντέλο μηχανικής μάθησης της εφαρμογής μας.
        Σας παρουσιάζουμε μερικές από τις πιο δημοφιλείς στρατηγικές που μπορείτε
        να χρησιμοποιήσετε για να ενισχύσετε το παιχνίδι σας.
      </p>
      <div className="space-y-8">
        {strategies.map((strategy, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-blue-600">{strategy.title}</h2>
            <p className="text-gray-800 mt-2">{strategy.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
