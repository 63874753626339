import React from 'react';
import { NavLink } from 'react-router-dom';

const AdminButton = () => {
  return (
    <li>
      <NavLink
        to="/admin"
        className="text-lg px-4 py-2 rounded-lg bg-purple-500 hover:bg-purple-700 text-white transition duration-300 text-center w-full"
      >
        Admin Panel
      </NavLink>
    </li>
  );
};

export default AdminButton;
