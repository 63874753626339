// src/components/Admin/MessagesTab.js
import React, { useEffect, useState } from 'react';
import { fetchAllMessages, replyToMessage } from '../../services/api';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Typography, CircularProgress,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';

const MessagesTab = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const loadMessages = async () => {
    try {
      const data = await fetchAllMessages();
      if (data.success) {
        setMessages(data.messages);
      } else {
        console.error('Failed to fetch messages:', data.error);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMessages();
  }, []);

  const handleReply = (message) => {
    setSelectedMessage(message);
    setReplyContent('');
    setDialogOpen(true);
  };

  const handleSendReply = async () => {
    try {
      const data = await replyToMessage(selectedMessage.id, replyContent);
      if (data.success) {
        alert('Reply sent successfully!');
        setDialogOpen(false);
      } else {
        alert(`Failed to send reply: ${data.error}`);
      }
    } catch (error) {
      alert('Error sending reply.');
      console.error(error);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          Contact Messages
        </Typography>
        <Table aria-label="messages table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((msg) => (
              <TableRow key={msg.id}>
                <TableCell>{msg.id}</TableCell>
                <TableCell>{msg.user_id}</TableCell>
                <TableCell>{/* Fetch username based on user_id if available */}</TableCell>
                <TableCell>{msg.message}</TableCell>
                <TableCell>{new Date(msg.timestamp).toLocaleString()}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleReply(msg)}>
                    Reply
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Reply Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Reply to Message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your reply below. The user will receive this message via email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reply"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSendReply} variant="contained" color="primary">
            Send Reply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessagesTab;
