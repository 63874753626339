import React from 'react';

const WinningNumbers = ({ last20Numbers, penultimateNumbers }) => {
  return (
    <div className="p-6 max-w-5xl mx-auto bg-white rounded-lg shadow-md">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Τελευταίοι Νικηφόροι Αριθμοί</h2>
        <div className="flex flex-wrap gap-4">
          {last20Numbers.map((num, index) => (
            <div key={index} className="flex items-center justify-center w-14 h-14 rounded-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold shadow-lg">
              {num}
            </div>
          ))}
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Προτελευταίοι Νικηφόροι Αριθμοί</h2>
        <div className="flex flex-wrap gap-4">
          {penultimateNumbers.map((num, index) => (
            <div key={index} className="flex items-center justify-center w-14 h-14 rounded-full bg-gradient-to-r from-purple-400 to-pink-500 text-white font-bold shadow-lg">
              {num}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WinningNumbers;
