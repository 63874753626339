import React from 'react';

const ComparisonOverview = ({ comparisonResult }) => {
  if (!comparisonResult) {
    return null;
  }

  const { comparison, latest_winning_numbers } = comparisonResult;

  return (
    <div className="comparison-result mt-10 p-8 bg-gradient-to-br from-purple-100 to-purple-300 rounded-lg shadow-xl border border-purple-400">
      <h2 className="text-3xl font-semibold text-purple-800 mb-6 text-center">
        Αποτελέσματα σύγκρισης
      </h2>
      <div className="mt-6">
        <h3 className="text-2xl font-bold text-purple-700 mb-4">Αριθμοί που κερδίζουν :</h3>
        <div className="flex flex-wrap gap-2">
          {latest_winning_numbers.map((number) => (
            <span
              key={number}
              className="text-lg font-bold bg-blue-200 text-blue-800 p-3 rounded-lg shadow-sm"
            >
              {number}
            </span>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-2xl font-bold text-purple-700 mb-4">Προηγούμενη πρόβλεψη:</h3>
        {Object.entries(comparison).map(([size, details], index) => (
          <div
            key={size}
            className="flex flex-col gap-4 p-4 rounded-lg mb-3 bg-white shadow-md"
          >
            <div>
              <span className="text-lg font-bold">
                Πρόβλεψη για {size} αριθμούς:
              </span>
              <div className="flex flex-wrap gap-2 mt-2">
                {details.predicted_numbers.map((number) => {
                  const isMatch = details.matches.includes(number);
                  return (
                    <span
                      key={number}
                      className={`text-xl font-bold mr-2 px-4 py-2 rounded-full ${
                        isMatch
                          ? 'bg-green-500 text-white shadow-lg border-2 border-green-700'
                          : 'bg-gray-200 text-gray-800'
                      }`}
                    >
                      {number}
                    </span>
                  );
                })}
              </div>
            </div>
            <div>
              <span className="text-lg font-bold text-green-700">
                Συμπτώσεις: {details.matches.join(', ') || 'Καμία'}
              </span>
            </div>
            <div>
              <span className="text-lg font-bold text-purple-800">
                Αριθμός Συμπτώσεων: {details.match_count}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonOverview;
