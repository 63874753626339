import React, { useState } from 'react';
import { registerUser } from '../services/api';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ toggleToLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); 
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await registerUser({ username, email, password });
      console.log('Registration successful:', response);
      setSuccess(true);
      setTimeout(() => toggleToLogin(), 2000);  
      navigate('/login');
    } catch (error) {
      console.error('Registration error:', error);
      setError('Registration failed. Please check your details and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');  
  };

  return (
    <div className="max-w-2xl mx-auto my-12 p-8 bg-white shadow-lg rounded-lg">
      <img 
        src="/logo.png" 
        alt="Logo" 
        className="mx-auto mb-4 w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
      />
      <h2 className="text-4xl font-bold text-center text-green-600">Εγγραφή </h2>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      {success && <div className="text-green-500 text-center mb-4">Η εγγραφή επιτυχής! Ανακατεύθυνση...</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="username" className="block text-lg font-medium">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full px-5 py-3 border rounded-lg text-lg"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-lg font-medium">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-5 py-3 border rounded-lg text-lg"
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-lg font-medium">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-5 py-3 border rounded-lg text-lg"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full ${loading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-700'} text-white py-3 rounded-lg text-lg`}
        >
          {loading ? 'Registering...' : 'Εγγραφή'}
        </button>
        <div className="text-center mt-6">
        <button
          type="button"
          onClick={handleBackToLogin}
          className="text-lg text-blue-600 hover:text-blue-800"
        >
          Επιστροφή στη Είσοδο
        </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
