import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Strategy from './pages/Strategy';
import About from './pages/About';
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import { initializeGA } from './services/analytics';
import { checkUserLoggedIn } from './services/api';
import Admin from './components/Admin/Admin';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleReload = () => {
    this.setState({ hasError: false });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-full flex-col">
          <h2>Κάτι πήγε στραβά. Δοκιμάστε να ανανεώσετε τη σελίδα.</h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={this.handleReload}
          >
            Ανανέωση σελίδας
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    initializeGA('G-MY5TMKK0QR');  

    const checkLoginStatus = async () => {
      try {
        const loggedIn = await checkUserLoggedIn(); 
        setIsLoggedIn(loggedIn);
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false);  
      }
    };
    checkLoginStatus();
  }, []); 

  const toggleToLogin = () => {
    setIsLoggedIn(false);  
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <div className="flex-grow">
          <ErrorBoundary>
            <Routes>
              <Route
                path="/"
                element={isLoggedIn ? <Home /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/strategy"
                element={isLoggedIn ? <Strategy /> : <Navigate to="/login" replace />}
              />
              <Route path="/about" element={<About />} />
              <Route
                path="/login"
                element={!isLoggedIn ? <LoginForm setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" replace />}
              />
              <Route
                path="/register"
                element={!isLoggedIn ? <RegistrationForm toggleToLogin={toggleToLogin} /> : <Navigate to="/" replace />}
              />
              {/* Admin Route */}
              <Route
                path="/admin"
                element={isLoggedIn ? <Admin /> : <Navigate to="/login" replace />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
