import React from 'react';

const PredictionResults = ({ predictions }) => {
  const gradientClasses = [
    'bg-gradient-to-r from-green-400 to-blue-500',
    'bg-gradient-to-r from-purple-500 to-pink-500',
    'bg-gradient-to-r from-yellow-400 to-orange-500',
    'bg-gradient-to-r from-blue-300 to-blue-800',
    'bg-gradient-to-r from-indigo-500 to-purple-800',
    'bg-gradient-to-r from-teal-300 to-lime-300',
    'bg-gradient-to-r from-pink-500 to-red-500',
    'bg-gradient-to-r from-yellow-300 to-yellow-600',
    'bg-gradient-to-r from-red-400 to-orange-600',
    'bg-gradient-to-r from-green-300 to-green-700',
    'bg-gradient-to-r from-gray-400 to-gray-700',
    'bg-gradient-to-r from-orange-400 to-red-500',
  ];

  return (
    <div className="prediction-results mt-6">
      <h2 className="text-3xl font-bold text-center text-blue-800 mb-6">Προβλέψεις</h2>
      {predictions && Object.keys(predictions).length > 0 ? (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {Object.entries(predictions).map(([key, value], index) => (
            <div
              key={key}
              className={`prediction-item p-6 rounded-lg shadow-md border border-gray-200 ${gradientClasses[index % gradientClasses.length]} text-white`}
            >
              <h3 className="text-xl font-bold mb-2">Μέγεθος Πρόβλεψης: {parseInt(key, 10) + 1}</h3>
              <p className="text-lg">{value.join(', ')}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">
          Δεν υπάρχουν διαθέσιμες προβλέψεις. Προσπαθήστε ξανά αργότερα.
        </p>
      )}
    </div>
  );
};

export default PredictionResults;
