import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ReviewsTab from './ReviewsTab';
import MessagesTab from './MessagesTab';
import FilteredUsers from './FilteredUsers';
import Users from './Users';

const Admin = () => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Tabs value={currentTab} onChange={handleChange} aria-label="admin dashboard tabs">
        <Tab label="Reviews" />
        <Tab label="Messages" />
        <Tab label="Filtered Users" />
        <Tab label="Users" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {currentTab === 0 && <ReviewsTab />}
        {currentTab === 1 && <MessagesTab />}
        {currentTab === 2 && <FilteredUsers />}
        {currentTab === 3 && <Users />}
      </Box>
    </Box>
  );
};

export default Admin;
