import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { logoutUser } from '../services/api';
import AdminButton from './Admin/AdminButton';

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = () => {
      const adminEmail = process.env.REACT_APP_ADMIN_EMAIL || 'dimitrios.n007@gmail.com';
      const userEmail = localStorage.getItem('user_email');
      setIsAdmin(userEmail && userEmail.toLowerCase() === adminEmail.toLowerCase());
    };

    if (isLoggedIn) {
      checkAdminStatus();
    } else {
      setIsAdmin(false);
    }
  }, [isLoggedIn]);

  const handleLogout = async () => {
    try {
      await logoutUser();
      setIsLoggedIn(false);
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_email');
      localStorage.removeItem('is_admin');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-gray-800 text-white py-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          <NavLink
            to="/"
            className="no-underline hover:text-yellow-400 transition duration-300"
          >
            BINO
          </NavLink>
        </h1>
        <ul className="flex space-x-8">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                `text-lg px-4 py-2 rounded-lg no-underline ${
                  isActive
                    ? 'bg-yellow-400 text-gray-900'
                    : 'hover:bg-gray-700 hover:text-yellow-400'
                } transition duration-300`
              }
            >
              Αρχική
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/strategy"
              className={({ isActive }) =>
                `text-lg px-4 py-2 rounded-lg no-underline ${
                  isActive
                    ? 'bg-yellow-400 text-gray-900'
                    : 'hover:bg-gray-700 hover:text-yellow-400'
                } transition duration-300`
              }
            >
              Στρατηγικές
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `text-lg px-4 py-2 rounded-lg no-underline ${
                  isActive
                    ? 'bg-yellow-400 text-gray-900'
                    : 'hover:bg-gray-700 hover:text-yellow-400'
                } transition duration-300`
              }
            >
              Σχετικά
            </NavLink>
          </li>
          {isAdmin && <AdminButton />}
          {isLoggedIn ? (
            <li>
              <button
                onClick={handleLogout}
                className="text-lg px-4 py-1 rounded-lg bg-red-500 hover:bg-blue-700 text-white transition duration-300"
              >
                Logout
              </button>
            </li>
          ) : (
            <li>
              <NavLink
                to="/login"
                className="text-lg px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-700 text-white transition duration-300"
              >
                Login
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
